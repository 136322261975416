<template>
	<div>
		<div v-if="this.$router.currentRoute.path=='/coalSystemManage'">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
				<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>元数据管理</el-breadcrumb-item>

			</el-breadcrumb>
			<el-main>
				<span style="font-size: 12px;">项目系统概述：</span>
				<div class="coalblock" v-for="item in options" :key="item.title">
					<span style="font-size: 12px; color:#9196a1 ;">{{item.title}}</span>
					<br>
					<span style="font-size: 24px;">{{item.num}}</span>

				</div>
				<el-form :inline="true">
					<el-form-item>
						<el-button type="primary" @click="addcoalSystem()">增加系统</el-button>
						<el-button type="danger" :disabled="multipleSelection.length==0" @click="deleteMany">批量删除系统</el-button>
					</el-form-item>
					<span style="font-size: 16px;">搜索：</span>
					<el-form-item>
						<el-input placeholder="请输入项目系统名称" v-model="coalName"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="queryByCoalName()">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button>重置</el-button>
					</el-form-item>
					<el-select v-model="datasizeValue" placeholder="数据量" @change="queryByCondition()">
						<el-option v-for="item in datasize" :key="item.value" :label="item.label+'G'" :value="item.label">
						</el-option>
					</el-select>
					<el-select v-model="subsysValue" placeholder="子系统个数" style="margin-left: 8px" @change="queryByCondition()">
						<el-option v-for="item in subsys_num" :key="item.value" :label="item.label" :value="item.label">
						</el-option>
					</el-select>
				</el-form>
				<el-card>
					<el-table :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" border stripe>
						<el-table-column type="selection" header-align="center" align="center" width="50">
						</el-table-column>
						<el-table-column prop="id" label="系统id" header-align="center" align="center" width="180">
						</el-table-column>
						<el-table-column prop="name" label="系统名称" header-align="center" align="center" width="180">
						</el-table-column>
						<el-table-column prop="subSystemCount" label="子系统个数" header-align="center" align="center" width="180">
						</el-table-column>
						<el-table-column prop="datasize" label="数据量" header-align="center" align="center" width="220">
						</el-table-column>
						<el-table-column prop="createdate" label="创建时间" header-align="center" align="center" width="220">
						</el-table-column>
						<el-table-column label="操作" header-align="center" align="center" width="180">
							<template slot-scope="scope">
								<el-button type="text" size="small">查看</el-button>
								<el-button type="text" size="small">修改</el-button>
								<el-button type="text" size="small" style="color: red;" @click="deleteSystem(scope.row.id)">删除</el-button>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" header-align="center" align="center" width="180">
						</el-table-column>

					</el-table>

					<!-- 分页区域 -->
					<div style="display: flex;justify-content: flex-end">
						<el-pagination
								background
								@current-change="currentChange"
								@size-change="sizeChange"
								:current-page="page"
								:page-size="size"
								layout="sizes, prev, pager, next, jumper, ->, total, slot"
								:total="total">
						</el-pagination>
					</div>
				</el-card>
			</el-main>
		</div>
		<div v-else-if>
			<!-- 用于子路由的显示 -->
			<router-view></router-view>
		</div>
		<div>
			<el-dialog title="新增系统"
					   :close-on-click-modal="false"
					   :visible.sync="systemDialog"
					   width="60%"
					   append-to-body>
				<el-form :model="addForm" ref="addForm" :label-position="addFormLabelPosition"
						 label-width="100px">
					<el-row style="margin-bottom: 10px">
						<el-col :span="11">
							<el-form-item label="添加系统">
								<div v-for="systemName in addForm.systemName" :key="systemName.id">
									<el-input style="width: 45%;margin-bottom: 5px" v-model="systemName.value"
											  placeholder="请输入系统名称"
											  @keyup.native="updateSystemOptions(systemName.id,systemName.value)"></el-input>
									<el-input style="width: 45%;margin-bottom: 5px" v-model="systemName.remark"
											  placeholder="请输入系统简介"></el-input>
								</div>
								<el-button class="addSystemButton" type="primary" size="small" @click="addSystemName" round>+
								</el-button>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="添加子系统">
								<el-select
										@change="systemChange(addForm.addSystemSelect)"
										style="width: 91%;margin-bottom: 5px" v-model="addForm.addSystemSelect" placeholder="请选择">
									<el-option
											v-for="item in addForm.addSystemOptions"
											:key="item.id"
											:value="item.value">
									</el-option>
								</el-select>
								<el-alert
										style="width: 91%; height: 100%;margin-bottom: 5px"
										v-if="subSystemAlter"
										:closable="false"
										title="请选择系统"
										type="error"
										effect="dark">
								</el-alert>
								<div v-for="subsystemName in addForm.subsystemName" :key="subsystemName.id">
									<el-input @focus="subSystemFocus"
											  :disabled=subSystemDisabled
											  style="width: 45%;margin-bottom: 5px" v-model="subsystemName.value"
											  @keyup.native="updateSubSystemOptions(subsystemName.id,subsystemName.value)"
											  placeholder="请输入子系统名称"></el-input>
									<el-input @focus="subSystemFocus"
											  :disabled=subSystemDisabled
											  style="width: 45%;margin-bottom: 5px" v-model="subsystemName.remark"
											  placeholder="添加子系统描述"></el-input>
								</div>
								<el-button class="addSystemButton" type="primary" size="small" @click="addSubsystemName" round>+
								</el-button>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin-bottom: 10px">
						<el-col :span="11">
							<el-form-item label="添加设备">
								<el-select
										@change="subSystemChange(addForm.addSystemSelect,addForm.addSubSystemSelect)"
										style="width: 91%;margin-bottom: 5px" v-model="addForm.addSubSystemSelect" placeholder="请选择">
									<el-option
											v-for="item in addForm.addSubSystemOptions"
											:key="item.id"
											:value="item.value">
									</el-option>
								</el-select>
								<el-alert
										style="width: 91%; height: 100%;margin-bottom: 5px"
										v-if="deviceAlter"
										:closable="false"
										title="请选择子系统"
										type="error"
										effect="dark">
								</el-alert>
								<div v-for="device in addForm.device" :key="device.id">
									<el-input @focus="deviceFocus"
											  :disabled=deviceDisabled
											  style="width: 45%;margin-bottom: 5px" v-model="device.value"
											  @keyup.native="updateDeviceOptions(device.id,device.value)"
											  placeholder="请输入设备名称"></el-input>
									<el-input @focus="deviceFocus"
											  :disabled=deviceDisabled
											  style="width: 45%;margin-bottom: 5px" v-model="device.remark"
											  placeholder="添加设备描述"></el-input>
								</div>
								<el-button class="addSystemButton" type="primary" size="small" @click="addDevice" round>+</el-button>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="添加子设备">
								<el-select
										@change="deviceChange(addForm.addSystemSelect,addForm.addSubSystemSelect,addForm.addDeviceSelect)"
										style="width: 91%;margin-bottom: 5px" v-model="addForm.addDeviceSelect" placeholder="请选择">
									<el-option
											v-for="item in addForm.addDeviceOptions"
											:key="item.id"
											:value="item.value">
									</el-option>
								</el-select>
								<el-alert
										style="width: 91%; height: 100%;margin-bottom: 5px"
										v-if="subDeviceAlter"
										:closable="false"
										title="请选择子系统"
										type="error"
										effect="dark">
								</el-alert>
								<div v-for="subDevice in addForm.subDevice" :key="subDevice.id">
									<el-input @focus="subDeviceFocus"
											  :disabled=subDeviceDisabled
											  style="width: 45%;margin-bottom: 5px" v-model="subDevice.value"
											  @keyup.native="updateSubDeviceOptions(subDevice.id,subDevice.value)"
											  placeholder="请输入附属设备名称"></el-input>
									<el-input @focus="subDeviceFocus"
											  :disabled=subDeviceDisabled
											  style="width: 45%;margin-bottom: 5px" v-model="subDevice.remark"
											  placeholder="添加附属设备描述"></el-input>
								</div>
								<el-button class="addSystemButton" type="primary" size="small" @click="addSubDevice" round>+</el-button>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin-bottom: 10px">
						<el-col :span="11">
							<el-form-item label="添加附属设备字段">
								<el-select
										@change="subDeviceChange(addForm.addSystemSelect,addForm.addSubSystemSelect,addForm.addDeviceSelect,addForm.addSubDeviceSelect)"
										style="width: 91%;margin-bottom: 5px" v-model="addForm.addSubDeviceSelect" placeholder="请选择">
									<el-option
											v-for="item in addForm.addSubDeviceOptions"
											:key="item.id"
											:value="item.value">
									</el-option>
								</el-select>
								<el-alert
										style="width: 91%; height: 100%;margin-bottom: 5px"
										v-if="subDeviceFieldAlter"
										:closable="false"
										title="请选择附属设备"
										type="error"
										effect="dark">
								</el-alert>
								<div v-for="subDeviceField in addForm.subDeviceField" :key="subDeviceField.id">
									<el-input
											@focus="subDeviceFieldFocus"
											:disabled=subDeviceFieldDisabled
											@keyup.native="updateSubDeviceFieldOptions(subDeviceField.id,subDeviceField.value)"
											style="width: 45%;margin-bottom: 5px" v-model="subDeviceField.value"
											placeholder="请输入字段名称"></el-input>
									<el-input
											@focus="subDeviceFieldFocus"
											:disabled=subDeviceFieldDisabled
											style="width: 45%;margin-bottom: 5px" v-model="subDeviceField.remark"
											placeholder="添加字段描述"></el-input>
								</div>
								<el-button class="addSystemButton" type="primary" size="small" @click="addSubDeviceField" round>+
								</el-button>
							</el-form-item>
						</el-col>
					</el-row>

					<!--<el-row style="margin-bottom: 10px">
						<el-col :span="8">
							<el-form-item label="新系统简介" prop="remark" style="margin-bottom: 10px">
								<el-input style="width: 100%" type="textarea" :rows="3" v-model="addForm.remark"
										  placeholder="请输入简介信息"></el-input>
							</el-form-item>
						</el-col>
					</el-row>-->
					<el-form-item label="当前操作员" prop="currOperatorName" style="margin-bottom: 10px">
						<el-input style="width: 20%" class="small-input" v-model="addForm.currOperatorName"
								  placeholder="管理员user" disabled></el-input>
					</el-form-item>

					<el-form-item style="margin-bottom: 10px">
						<el-button type="primary" @click="submitForm()">立即创建</el-button>
						<el-button @click="resetForm('addForm')">重置</el-button>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
       				<el-button @click="systemDialog = false">取 消</el-button>
       				<el-button type="primary" @click="systemDialog = false">确 定</el-button>
      			</span>
			</el-dialog>
		</div>
</div>
</template>

<script>
    export default {
        name: "coalSystemManage",
        data() {
            return {
				systemDialog:false,
				subSystemAlter: false,
				deviceAlter: false,
				subDeviceAlter: false,
				subDeviceFieldAlter:false,
				subSystemDisabled: false,
				deviceDisabled: false,
				subDeviceDisabled: false,
				subDeviceFieldDisabled:false,
				addFormLabelPosition: 'top',
				addForm: {
					data: [{
						id: 0,
						value: '', //系统
						remark: '',
						subsystemName: [{
							id: 0,
							value: '', //子系统
							remark: '',
							device: [{
								id: 0,
								value: '', //设备
								remark: '',
								subDevice: [{
									id: 0,
									value: '', //附属设备
									remark: '',
									subDeviceField: [{
										id: 0,
										value: '', //字段
										remark: '',
									}]
								}]
							}]
						}]
					}],
					addSubDeviceSelect:'',
					addSubDeviceOptions:[
						{
							id: 0,
							value: '',
						}
					],
					addDeviceSelect: '',
					addDeviceOptions: [
						{
							id: 0,
							value: '',
						}
					],
					addSubSystemSelect: '',
					addSubSystemOptions: [
						{
							id: 0,
							value: '',
						}
					],
					addSystemSelect: '',
					addSystemOptions: [
						{
							id: 0,
							value: '',
						}
					],
					coalName: '',
					remark: '',
					currOperator: 0,
					currOperatorName:'',
					subDeviceField: [{
						id: 0,
						value: '',
						remark: '',
					}
					],
					device: [{
						id: 0,
						value: '',
						remark: '',
					}
					],
					subDevice: [{
						id: 0,
						value: '',
						remark: '',
					}
					],
					subsystemName: [{
						id: 0,
						value: '',
						remark: '',
					}
					],
					serviceNum: '',
					disaster: '',
					miningWay: '',
					quality: '',
					systemName: [{
						id: 0,
						value: '',
						remark: '',
					}
					],
					address: '',
					output: '',
					date: '',
				},
				multipleSelection: [],
				datasizeLeft:'',
				datasizeRight:'',
				subSystemCountLeft:'',
				subSystemCountRight:'',
				coalName:'',
				page:1,  //当前第几页
				size:10, //当前每页页数
				total:100, //全部数据量
                datasizeValue: '',
                subsysValue: '',
                datasize: [{
                    value: '选项1',
                    label: '0~100'
                }, {
                    value: '选项2',
                    label: '100~200'
                }, {
                    value: '选项3',
                    label: '200~300'
                }, {
                    value: '选项4',
                    label: '300~500'
                }],
                subsys_num: [{
                    value: '选项1',
                    label: '1~3'
                }, {
                    value: '选项2',
                    label: '4~6'
                }, {
                    value: '选项3',
                    label: '7~9'
                }, {
                    value: '选项4',
                    label: '9个以上'
                }],
                tableData: [{
					id: 1,
                    name: '主运输系统',
					subSystemCount: 3,
                    datasize: 255,
                    remark: '无',
					createdate:''
                }, {
					id: 1,
                    name: '通风系统',
					subSystemCount: 5,
                    datasize: 126,
                    remark: '无',
					createdate:''
                }, {
					id: 1,
                    name: '主排水系统',
					subSystemCount: 2,
                    datasize: 334,
                    remark: '无',
					createdate:''
                }, {
					id: 1,
                    name: '安全检测',
					subSystemCount: 6,
                    datasize: 87,
                    remark: '无',
					createdate:''
                }],
                options: [{
                    title: '总数据量',
                    num: '1.2T'
                }, {
                    title: '系统个数',
                    num: '8个'
                }, {
                    title: '子系统系统个数',
                    num: '12个'
                }, {
                    title: '设备个数',
                    num: '125'
                }],
            }
        },
		mounted(){
			this.initData();
			this.addForm.currOperator = JSON.parse(
          window.sessionStorage.getItem("user")
      ).userId;
      this.addForm.currOperatorName = JSON.parse(
          window.sessionStorage.getItem("user")
      ).username;
		},
        methods: {
			submitForm(){
				console.log(this.addForm);
				this.$confirm('此操作将添加该系统, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.postRequest('/coalSystemManage/insertSystem',this.addForm).then(resp =>{
						if(resp.data){
							this.initData();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.addForm.data = [{
					id: 0,
					value: '',
					remark: '',
					subsystemName: [{
						id: 0,
						value: '',
						remark: '',
						device: [{
							id: 0,
							value: '',
							remark: '',
							subDevice: [{
								id: 0,
								value: '',
								remark: '',
								subDeviceField: [{
									id: 0,
									value: '',
									remark: '',
								}]
							}]
						}]
					}]
				}]
				this.addForm.systemName = [{
					id: 0,
					value: '',
					remark: '',
				}]
				this.addForm.subsystemName = [{
					id: 0,
					value: '',
					remark: '',
				}]
				this.addForm.device = [{
					id: 0,
					value: '',
					remark: '',
				}]
				this.addForm.subDevice = [{
					id: 0,
					value: '',
					remark: '',
				}]
				this.addForm.subDeviceField = [{
					id: 0,
					value: '',
					remark: '',
				}]
				this.addForm.addSystemOptions = [{
					id: 0,
					value: ''
				}]
				this.addForm.addSubSystemOptions = [{
					id: 0,
					value: ''
				}]
				this.addForm.addDeviceOptions = [{
					id: 0,
					value: ''
				}]
				this.addForm.addSubDeviceOptions = [{
					id: 0,
					value: ''
				}]
				this.addForm.addSystemOptions[0].id = this.addForm.systemName[0].id
				this.addForm.addSystemOptions[0].value = this.addForm.systemName[0].value
				this.addForm.addSystemSelect = ''
				this.subSystemAlter = false;
				this.subSystemDisabled = false;
				this.addForm.addSubSystemOptions[0].id = this.addForm.subsystemName[0].id
				this.addForm.addSubSystemOptions[0].value = this.addForm.subsystemName[0].value
				this.addForm.addSubSystemSelect = ''
				this.deviceAlter = false;
				this.deviceDisabled = false;
				this.addForm.addDeviceOptions[0].id = this.addForm.device[0].id
				this.addForm.addDeviceOptions[0].value = this.addForm.device[0].value
				this.addForm.addDeviceSelect = ''
				this.subDeviceAlter = false;
				this.subDeviceDisabled = false;
				this.addForm.addSubDeviceOptions[0].id = this.addForm.subDevice[0].id
				this.addForm.addSubDeviceOptions[0].value = this.addForm.subDevice[0].value
				this.addForm.addSubDeviceSelect = ''
				this.subDeviceFieldAlter = false;
				this.subDeviceFieldDisabled = false;
			},
			addSystemName() {
				let data = {
					id: 0,
					value: '',
					remark: '',
					subsystemName: [{
						id: 0,
						value: '',
						remark: '',
						device: [{
							id: 0,
							value: '',
							remark: '',
							subDevice: [{
								id: 0,
								value: '',
								remark: '',
								subDeviceField: [{
									id: 0,
									value: '',
									remark: '',
								}]
							}]
						}]
					}]
				}
				let id = this.addForm.systemName.length;
				let data1 = {
					id: 0,
					value: '',
					remark: '',
				}
				let data2 = {
					id: id,
					value: data.value
				}
				data.id = id
				data1.id = id
				this.addForm.systemName.push(data1)
				this.addForm.addSystemOptions.push(data2)
				this.addForm.data.push(data)
			},
			addSubsystemName() {
				let data = {
					id: 0,
					value: '',
					remark: '',
					device: [{
						id: 0,
						value: '',
						remark: '',
						subDevice: [{
							id: 0,
							value: '',
							remark: '',
							subDeviceField: [{
								id: 0,
								value: '',
								remark: '',
							}]
						}]
					}]
				}
				let id = this.addForm.subsystemName.length
				let data1 = {
					id: 0,
					value: '',
					remark: '',
				}
				let data2 = {
					id: id,
					value: data.value
				}
				data.id = id
				data1.id = id
				this.addForm.subsystemName.push(data1)
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						this.addForm.data[i].subsystemName.push(data)
						break;
					}
				}
				this.addForm.addSubSystemOptions.push(data2)
			},
			addDevice() {
				let data = {
					id: 0,
					value: '',
					remark: '',
					subDevice: [{
						id: 0,
						value: '',
						remark: '',
						subDeviceField: [{
							id: 0,
							value: '',
							remark: '',
						}]
					}]
				}
				let id = this.addForm.device.length
				let data1 = {
					id: 0,
					value: '',
					remark: '',
				}
				let data2 = {
					id: id,
					value: data.value
				}
				data.id = id
				data1.id = id
				this.addForm.device.push(data1)
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
								this.addForm.data[i].subsystemName[j].device.push(data)
								break;
							}
						}
						break;
					}
				}
				this.addForm.addDeviceOptions.push(data2)
			},
			addSubDevice() {
				let data = {
					id: 0,
					value: '',
					remark: '',
					subDeviceField: [{
						id: 0,
						value: '',
						remark: '',
					}]
				}
				let id = this.addForm.subDevice.length
				let data1 = {
					id: 0,
					value: '',
					remark: '',
				}
				let data2 = {
					id: id,
					value: data.value
				}
				data.id = id
				data1.id = id
				this.addForm.subDevice.push(data1)
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
										this.addForm.data[i].subsystemName[j].device[k].subDevice.push(data)
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
				this.addForm.addSubDeviceOptions.push(data2)
			},
			addSubDeviceField() {
				let data = {
					id: 0,
					value: '',
					remark: '',
				}
				let id = this.addForm.subDeviceField.length
				data.id = id
				this.addForm.subDeviceField.push(data)
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
										for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
											if (this.addForm.addSubDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value) {
												this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.push(data)
												break;
											}
										}
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
			},
			updateSystemOptions(id, value) {
				for (let i = 0; i < this.addForm.addSystemOptions.length; i++) {
					if (id === this.addForm.addSystemOptions[i].id) {
						this.addForm.addSystemOptions[i].value = value
						break;
					}
				}
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (id === this.addForm.data[i].id) {
						this.addForm.data[i].value = value
						break;
					}
				}
			},
			systemChange(system) {
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						if (this.addForm.data[i].subsystemName.length === 1) {
							this.addForm.subsystemName = [{
								id: this.addForm.data[i].subsystemName[0].id,
								value: this.addForm.data[i].subsystemName[0].value,
								remark: this.addForm.data[i].subsystemName[0].remark
							}]
						} else {
							this.addForm.subsystemName = []
							for (let m = 0; m < this.addForm.data[i].subsystemName.length; m++) {
								let data = {
									id: this.addForm.data[i].subsystemName[m].id,
									value: this.addForm.data[i].subsystemName[m].value,
									remark: this.addForm.data[i].subsystemName[m].remark,
								}
								this.addForm.subsystemName.push(data)
							}
						}
						break;
					}
				}
				this.addForm.addSubSystemOptions = []
				this.addForm.addSubSystemSelect = ''
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							let data = {
								id: this.addForm.data[i].subsystemName[j].id,
								value: this.addForm.data[i].subsystemName[j].value
							}
							this.addForm.addSubSystemOptions.push(data)
						}
						break;
					}
				}
				this.addForm.addDeviceSelect = ''
				this.addForm.addDeviceOptions = [{
					id: 0,
					value: ''
				}
				]
				this.addForm.addSubDeviceSelect = ''
				this.addForm.addSubDeviceOptions = [{
					id: 0,
					value: ''
				}
				]
				this.addForm.device = [{
					id: 0,
					value: '',
					remark: ''
				}]
				this.addForm.subDevice = [{
					id: 0,
					value: '',
					remark: ''
				}]
				this.addForm.subDeviceField = [{
					id: 0,
					value: '',
					remark: ''
				}]
				this.subSystemAlter = false;
				this.subSystemDisabled = false;
			},
			subSystemFocus() {
				if (this.addForm.addSystemSelect === '') {
					this.subSystemAlter = true;
					this.subSystemDisabled = true;
				}
			},
			updateSubSystemOptions(id, value) {
				for (let i = 0; i < this.addForm.addSubSystemOptions.length; i++) {
					if (id === this.addForm.addSubSystemOptions[i].id) {
						this.addForm.addSubSystemOptions[i].value = value
						break;
					}
				}
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (id === this.addForm.data[i].subsystemName[j].id) {
								this.addForm.data[i].subsystemName[j].value = value
								break;
							}
						}
						break;
					}
				}
			},
			subSystemChange(system, subSystem) {
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (subSystem === this.addForm.data[i].subsystemName[j].value) {
								if (this.addForm.data[i].subsystemName[j].device.length === 1) {
									this.addForm.device = [{
										id: this.addForm.data[i].subsystemName[j].device[0].id,
										value: this.addForm.data[i].subsystemName[j].device[0].value,
										remark: this.addForm.data[i].subsystemName[j].device[0].remark
									}]
								} else {
									this.addForm.device = []
									for (let m = 0; m < this.addForm.data[i].subsystemName[j].device.length; m++) {
										let data = {
											id: this.addForm.data[i].subsystemName[j].device[m].id,
											value: this.addForm.data[i].subsystemName[j].device[m].value,
											remark: this.addForm.data[i].subsystemName[j].device[m].remark,
										}
										this.addForm.device.push(data)
									}
								}
								break;
							}
						}
						break;
					}
				}
				this.addForm.addDeviceOptions = []
				this.addForm.addDeviceSelect = ''
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (subSystem === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									let data = {
										id: this.addForm.data[i].subsystemName[j].device[k].id,
										value: this.addForm.data[i].subsystemName[j].device[k].value
									}
									this.addForm.addDeviceOptions.push(data)
								}
								break;
							}
						}
						break;
					}
				}
				this.addForm.subDevice = [{
					id: 0,
					value: '',
					remark: ''
				}]
				this.addForm.subDeviceField = [{
					id: 0,
					value: '',
					remark: ''
				}]
				this.deviceAlter = false;
				this.deviceDisabled = false;
			},
			deviceFocus() {
				if (this.addForm.addSubSystemSelect === '') {
					this.deviceAlter = true;
					this.deviceDisabled = true;
				}
			},
			updateDeviceOptions(id, value) {
				for (let i = 0; i < this.addForm.addDeviceOptions.length; i++) {
					if (id === this.addForm.addDeviceOptions[i].id) {
						this.addForm.addDeviceOptions[i].value = value
						break;
					}
				}
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (id === this.addForm.data[i].subsystemName[j].device[k].id) {
										this.addForm.data[i].subsystemName[j].device[k].value = value
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
			},
			deviceChange(system, subSystem, device) {
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (subSystem === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (device === this.addForm.data[i].subsystemName[j].device[k].value) {
										if (this.addForm.data[i].subsystemName[j].device[k].subDevice.length === 1) {
											this.addForm.subDevice = [{
												id: this.addForm.data[i].subsystemName[j].device[k].subDevice[0].id,
												value: this.addForm.data[i].subsystemName[j].device[k].subDevice[0].value,
												remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[0].remark
											}]
										} else {
											this.addForm.subDevice = []
											for (let m = 0; m < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; m++) {
												let data = {
													id: this.addForm.data[i].subsystemName[j].device[k].subDevice[m].id,
													value: this.addForm.data[i].subsystemName[j].device[k].subDevice[m].value,
													remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[m].remark,
												}
												this.addForm.subDevice.push(data)
											}
										}
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
				this.addForm.addSubDeviceOptions = []
				this.addForm.addSubDeviceSelect = ''
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (subSystem === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (device === this.addForm.data[i].subsystemName[j].device[k].value) {
										for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
											let data = {
												id: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].id,
												value: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value
											}
											this.addForm.addSubDeviceOptions.push(data)
										}
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
				this.addForm.subDeviceField = [{
					id: 0,
					value: '',
					remark: ''
				}]
				this.subDeviceAlter = false;
				this.subDeviceDisabled = false;
			},
			subDeviceFocus() {
				if (this.addForm.addDeviceSelect === '') {
					this.subDeviceAlter = true;
					this.subDeviceDisabled = true;
				}
			},
			updateSubDeviceOptions(id, value) {
				for (let i = 0; i < this.addForm.addSubDeviceOptions.length; i++) {
					if (id === this.addForm.addSubDeviceOptions[i].id) {
						this.addForm.addSubDeviceOptions[i].value = value
						break;
					}
				}
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
										for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
											if (id === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].id) {
												this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value = value
												break;
											}
										}
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
			},
			subDeviceChange(system, subSystem, device, subDevice) {
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (system === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (subSystem === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (device === this.addForm.data[i].subsystemName[j].device[k].value) {
										for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
											if (subDevice === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value) {
												if (this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.length === 1) {
													this.addForm.subDeviceField = [{
														id: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[0].id,
														value: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[0].value,
														remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[0].remark
													}]
												} else {
													this.addForm.subDeviceField = []
													for (let m = 0; m < this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.length; m++) {
														let data = {
															id: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].id,
															value: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].value,
															remark: this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].remark,
														}
														this.addForm.subDeviceField.push(data)
													}
												}
												break;
											}
										}
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
				this.subDeviceFieldAlter = false;
				this.subDeviceFieldDisabled = false;
			},
			subDeviceFieldFocus() {
				if (this.addForm.addSubDeviceSelect === '') {
					this.subDeviceFieldAlter = true;
					this.subDeviceFieldDisabled = true;
				}
			},
			updateSubDeviceFieldOptions(id, value) {
				for (let i = 0; i < this.addForm.data.length; i++) {
					if (this.addForm.addSystemSelect === this.addForm.data[i].value) {
						for (let j = 0; j < this.addForm.data[i].subsystemName.length; j++) {
							if (this.addForm.addSubSystemSelect === this.addForm.data[i].subsystemName[j].value) {
								for (let k = 0; k < this.addForm.data[i].subsystemName[j].device.length; k++) {
									if (this.addForm.addDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].value) {
										for (let l = 0; l < this.addForm.data[i].subsystemName[j].device[k].subDevice.length; l++) {
											if (this.addForm.addSubDeviceSelect === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].value) {
												for (let m = 0; m < this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField.length; l++) {
													if (id === this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].id) {
														this.addForm.data[i].subsystemName[j].device[k].subDevice[l].subDeviceField[m].value = value
														break;
													}
												}
												break;
											}
										}
										break;
									}
								}
								break;
							}
						}
						break;
					}
				}
			},
			deleteMany(){
				this.$confirm('此操作将永久删除【' + this.multipleSelection.length + '】条记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() =>{
					let ids = '?';
					this.multipleSelection.forEach(item => {
						ids += 'ids=' + item.id + '&';
					});

					this.deleteRequest('/coalSystemManage/deleteSystem/'+ids).then(resp =>{
						if (resp.data){
							this.initData();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			deleteSystem(id){
				//弹框询问用户是否删除组件
				this.$confirm('此操作将永久删除该系统, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = '?ids='+id;
					this.deleteRequest('/coalSystemManage/deleteSystem/'+ids).then(resp =>{
						if (resp.data){
							this.initData();
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
        	queryByCoalName(){
        		this.datasizeValue = '';
        		this.subsysValue = '';
				this.getRequest('/coalSystemManage/queryByCondition/?coalName=' + this.coalName).then(resp =>{
					if (resp.data){
						this.tableData = resp.data;
						this.total = resp.data.size;
					}
				})
			},
			queryByCondition(){
				var split = this.datasizeValue.split('~'); //这些范围值在前端后端处理都行
				this.datasizeLeft = split[0];
				this.datasizeRight = split[1];

				var split1 = this.subsysValue.split('~');
				this.subSystemCountLeft = split1[0];
				this.subSystemCountRight = split1[1];

				// console.log(this.coalName)

				if (this.datasizeValue !== '' && this.subsysValue !== ''){
					this.getRequest('/coalSystemManage/queryByCondition/?datasizeLeft=' + this.datasizeLeft + '&datasizeRight=' + this.datasizeRight
							+ '&subSystemCountLeft=' + this.subSystemCountLeft + '&subSystemCountRight=' + this.subSystemCountRight).then(resp =>{
						if (resp.data){
							this.tableData = resp.data;
							this.total = resp.data.size;
						}
					})
				}else if (this.datasizeValue !== '' && this.subsysValue !== ''){
					this.getRequest('/coalSystemManage/queryByCondition/?datasizeLeft=' + this.datasizeLeft + '&datasizeRight=' + this.datasizeRight
							+ '&subSystemCountLeft=' + this.subSystemCountLeft + '&subSystemCountRight=' + this.subSystemCountRight).then(resp =>{
						if (resp.data){
							this.tableData = resp.data;
							this.total = resp.data.size;
						}
					})
				}else if (this.datasizeValue !== '' && this.subsysValue == ''){
					this.getRequest('/coalSystemManage/queryByCondition/?datasizeLeft=' + this.datasizeLeft + '&datasizeRight=' + this.datasizeRight).then(resp =>{
						if (resp.data){
							this.tableData = resp.data;
							this.total = resp.data.size;
						}
					})
				}else if (this.datasizeValue == '' && this.subsysValue !== ''){
					this.getRequest('/coalSystemManage/queryByCondition/?subSystemCountLeft=' + this.subSystemCountLeft + '&subSystemCountRight=' + this.subSystemCountRight).then(resp =>{
						if (resp.data){
							this.tableData = resp.data;
							this.total = resp.data.size;
						}
					})
				}
			},
          initData(){
            this.getRequest('/coalSystemManage/getCoalSystemInfoByPage/?page=' + this.page + '&size='+this.size).then(resp=>{
              if (resp.data){
                this.tableData = resp.data;
                this.total = resp.data.size;
              }
            });
            this.getRequest('/coalSystemManage/getSystemOverview').then(resp =>{
              if (resp.data){
                this.options[0].num = resp.data.datasize + 'G';
                this.options[1].num = resp.data.systemCount + '个';
                this.options[2].num = resp.data.subSystemCount + '个';
                this.options[3].num = resp.data.equipmentCount + '个';
              }
            })
          },
			handleSelectionChange(val){
				this.multipleSelection = val;
			},
			currentChange(currentPage){
				this.page=currentPage;
				this.initData();
			},
			sizeChange(currentSize) {
				this.size = currentSize;
				this.initData();
			},
            addcoalSystem() {
				this.systemDialog = true;
                /*this.$router.push({
                    path: '/addSystem'
                })*/
            }
        }
    }
</script>

<style scoped>
    .coalblock {
        /* width: 115px;
        height: 77px;
        border: solid 1px #dcdfe6;
        padding-top: 20px;
        margin-left: 25px;
        text-align: center;
        display: inline-block; */
		text-align: center;
		display: inline-block;
		padding: 3px 5px;
    }

    .el-form {
        margin-top: 12px;
    }

    .small-input {
        width: 136px;
        margin-right: 10px;
    }
</style>
